<template>
    <front-layout>
        <div class="flex-wrapper-full-background d-flex  fill-height" style="width: 100%">
            <v-card class="mx-auto my-auto pa-5" max-width="900" min-width="500" raised>
                <v-card-title>{{$t('other.' + other.btnsup)}}</v-card-title>
                <v-container>
                    <v-form>
                        <v-row>
                            <v-col>
                                <v-text-field  :error-messages="errors.email"  label="Email" v-model="email"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field  :error-messages="errors.password"  type="password" label="Password" v-model="password"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field type="password" :error-messages="errors.password_confirmation"  label="Password" v-model="password_confirmation"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-select
                                    v-model="industry_id"
                                    label="Industry"
                                    item-text="name"
                                    item-value="id"
                                    :items="industries"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn @click="handleSignUp">{{$t('other.' + other.btnsup)}}</v-btn>
                            </v-col>
                            <!--     <v-col>
                                     <login-with-github />
                                 </v-col>
                                 <v-col>
                                     <login-with-google />
                                 </v-col>-->
                        </v-row>
                    </v-form>
                </v-container>
            </v-card>
        </div>
    </front-layout>
</template>

<script>
    import FrontLayout from "../layouts/FrontLayout";
    import Industry from "@/models/Industry";
    import {mapMutations} from "vuex";
    import other from "@/configs/other";

    export default {
        name: "Login",
        components: {FrontLayout},
        data: function () {
            return {
                email: '',
                password: '',
                password_confirmation: '',
                industry_id: '',
                industries: [],
                errors: {},
                user: [],
                other
            }
        },
        async mounted() {
            this.industries = await Industry.get();
        },
        methods: {
            async handleSignUp() {
                const {email, password, password_confirmation, industry_id} = this;
                await this.$auth.register({
                    data: {email, password, password_confirmation, industry_id},
                    success() {
                        this.$auth.ready();
                        this.showSnackBar({color: 'success', timeout: 3000, text: 'Saved successfully'});
                    },
                    staySignedIn: true,
                    redirect: {name: 'dashboard.index'},

                }).catch(e => this.errors = e.response.data.errors)
            },
            ...mapMutations(['showSnackBar']),
        }
    }
</script>

<style scoped lang="scss">

</style>
